/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  z-index: 10;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(107, 114, 128, 0.8);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: #0984e3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0984e3;
}
.MuiDialog-paper {
  max-width: 80% !important;
}

.header-center .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

/* Make autofill transparent */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 100px transparent inset !important;
}

body {
  overscroll-behavior: none;
}

@media (max-width: 768px) {
  .date-range-picker .rdrDefinedRangesWrapper {
    display: none;
  }
}

.chart-container canvas {
  margin: auto;
  height: 400px !important;
  width: 400px !important;
}

.MuiStepper-root {
  width: 100%;
}
